import type { PhotoURL } from './photo_url'
import BaseModel from './base'
import type { _BaseType } from './_base_type'

export type OfferType = _BaseType & {
  name: string
  group: string
  highlights?: string
  description?: string
  quantity: number
  allocatedQuantity: number
  code: string

  days?: string[]
  startAt?: Date
  endAt?: Date
  dobOnly: boolean
  method: string
  info?: string

  posVoucherCode: string
  value: number
  valuePercent: number
  minOrder: number
  maxDiscount: number

  purchaseViaPoint: number

  useApp: boolean
  useOffline: boolean
  isPrivate: boolean
  isVisible?: boolean // visible to cart list

  limitByOutlets?: boolean
  limitByProducts?: boolean
  limitPerPerson: number

  purchaseOnly: boolean

  terms?: string[]
  photo?: string
  photoURL?: PhotoURL
  status: number
  remark?: string
}

export class OfferModel extends BaseModel {
  constructor() {
    super('offers')

    this.uniques = [
      ['code'],
    ]

    this.required = ['name', 'code', 'quantity', 'allocatedQuantity']

    this.fields = {
      offerId: { type: 'string' },
      outletId: { type: 'string' },
      enable: { type: 'boolean' },
    }
  }
}
